<template>
    <div class="row">
        <HeaderRow class="p-0" v-if="getUserInfo" :text="this.getUserInfo.id"></HeaderRow>
        <div class="row p-0">
            <div class="col-0 col-lg-3 sidebar p-0">
                <SideBar class="d-none d-lg-block"/>
            </div>
            <div class="col-12 col-lg-9 p-0 ms-2">
                <b-overlay rounded="sm" :opacity="0.4">
                    <slot />
                </b-overlay>
            </div>
        </div>
    </div>
</template>

<script>
import SideBar from "@/components/sidebar/SideBar";
import HeaderRow from "@/components/header/HeaderRow";
import {mapActions, mapGetters} from "vuex";

export default {
    name: "DefaultLayout",
    components: {SideBar, HeaderRow},
    computed: {
        ...mapGetters(['getUserInfo'])
    },
    methods: {
        ...mapActions(['fetchUser'])
    },
    mounted() {
        this.fetchUser()
    }
}
</script>

<style scoped>
.sidebar {
    width: 230px;
}
</style>