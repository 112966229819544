import apiClient from "@/plugins/axios";

export default {
    actions: {
        fetchUserCompanies(context) {
            return new Promise((resolve, reject) => {
                apiClient.get(`partners/company`)
                    .then((response) => {

                        let userCompany = response.data['hydra:member']

                        context.commit('updateUserCompany', userCompany)
                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })
            })
        },
    },
    mutations: {
        updateUserCompany(state, userCompany) {
            state.userCompanies = userCompany
        },
    },
    state: {
        userCompanies: [],
    },
    getters: {
        getUserCompanies(state) {
            return state.userCompanies
        },
    },
}
