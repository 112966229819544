import apiClient from "../../plugins/axios";

export default {
    actions: {
        checkEmailAvailability(context, email) {
            return new Promise(((resolve, reject) => {
                apiClient
                    .post('/users/is_unique_email', {email})
                    .then(response => {
                        context.commit('updateEmailAvailability', response.data['isAvailable'])
                        resolve()
                    })
                    .catch((reason) => {
                        reject(reason)
                    })
            }))
        }
    },
    mutations: {
        updateEmailAvailability(state, model) {
            state.emailAvailability = model
        },
    },
    state: {
        emailAvailability: false,
    },
    getters: {
        isEmailAvailable(state) {
            return state.emailAvailability
        }
    },
}
