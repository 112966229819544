import apiClient from "@/plugins/axios";

export default {
    actions: {
        fetchPayments(context) {
            return new Promise((resolve, reject) => {
                apiClient.get(`/partner_payment_applications`)
                    .then((response) => {

                        let payments = response.data['hydra:member']

                        context.commit('updatePayments', payments)
                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })
            })
        },
        pushPaymentApplication(context, data) {
            return new Promise((resolve, reject) => {
                apiClient.post(`/partner_payment_applications/make`, data)
                    .then((response) => {

                        let payments = response.data['hydra:member']

                        context.commit('updatePayments', payments)
                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })
            })
        },
        updatePaymentApplication(context, data) {
            return new Promise((resolve, reject) => {
                apiClient.post(`/partner_payment_applications/make_payed`, data)
                    .then((response) => {

                        let payments = response.data['hydra:member']

                        context.commit('updatePayments', payments)
                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })
            })
        },
    },
    mutations: {
        updatePayments(state, payments) {
            state.payments = payments
        },
    },
    state: {
        payments: [],
    },
    getters: {
        getPayments(state) {
            return state.payments
        },
    },
}
