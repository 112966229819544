<template>
    <div>
        <b-overlay rounded="sm" :show="$root.showOverlay" :opacity="0.4">
            <slot />
        </b-overlay>
    </div>
</template>

<script>

export default {
    name: "EmptyLayout",
    components: {}
}
</script>

<style scoped>

</style>