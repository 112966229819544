import {createRouter, createWebHistory} from "vue-router"
import MainPage from "@/pages/main/MainPage";
import store from "../store/main"

const ifAuthenticated = (to, from, next) => {
    if (store.getters.isAuthenticated) {
        next()

        return
    }

    next('/login')
}

const ifNotAuthenticated = (to, from, next) => {
    if (store.getters.isAuthenticated) {
        next()

        return
    }

    next()
}

const ifAdmin = (to, from, next) => {
    if (store.getters.isAdmin) {
        next()

        return
    }

    next('/')
}

const routes = [
    {
        name: 'main',
        path: '/',
        component: MainPage,
        beforeEnter: ifAuthenticated,
    },
    {
        name: 'registration',
        path: '/registration',
        meta: {layout: 'EmptyLayout'},
        component: () => import('../pages/auth/RegistrationPage'),
        beforeEnter: ifNotAuthenticated,
    },
    {
        name: 'login',
        path: '/login',
        meta: {layout: 'EmptyLayout'},
        component: () => import('../pages/auth/LoginPage'),
        beforeEnter: ifNotAuthenticated,
    },
    {
        name: 'balans',
        path: '/balans',
        meta: {layout: 'DefaultLayout'},
        component: () => import('../pages/main/BalansPage'),
        beforeEnter: ifAuthenticated,
    },
    {
        name: 'withdraw',
        path: '/withdraw',
        meta: {layout: 'DefaultLayout'},
        component: () => import('../pages/main/WithDraw'),
        beforeEnter: ifAuthenticated,
    },
    {
        name: 'help',
        path: '/help',
        meta: {layout: 'DefaultLayout'},
        component: () => import('../pages/main/HelpPage'),
        beforeEnter: ifAuthenticated,
    },
    {
        name: 'learn',
        path: '/learn',
        meta: {layout: 'DefaultLayout'},
        component: () => import('../pages/main/LearnPage'),
        beforeEnter: ifAuthenticated,
    },
    {
        name: 'personal-info',
        path: '/personal-info',
        meta: {layout: 'DefaultLayout'},
        component: () => import('../pages/main/PersonInfoPage'),
        beforeEnter: ifAuthenticated,
    },
    {
        name: 'manager',
        path: '/manager',
        meta: {layout: 'DefaultLayout'},
        component: () => import('../pages/main/ManagerPage'),
        beforeEnter: ifAdmin,
    },
    {
        name: 'payment',
        path: '/payment',
        meta: {layout: 'DefaultLayout'},
        component: () => import('../pages/main/PaymentPage'),
        beforeEnter: ifAdmin,
    },
]

export default createRouter({
    history: createWebHistory(),
    routes
})
