<template>
    <div id="app" class="container-fluid mh-100">
        <component :is="layout">
            <router-view/>
        </component>
    </div>
</template>

<script>
import DefaultLayout from "@/components/layout/DefaultLayout";
import EmptyLayout from "@/components/layout/EmptyLayout";
import SideBar from "@/components/sidebar/SideBar";
import {useRoute} from "vue-router"
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import {mapActions, mapGetters} from "vuex";

export default {
    name: 'App',
    components: {
        DefaultLayout, EmptyLayout, SideBar, Loading
    },
    data() {
        return {
            isLoading: true
        }
    },
    computed: {
        ...mapGetters(['getUserRoles', 'getUserInfo', 'isAuthenticated']),
        layout() {
            const route = useRoute()
            return route.meta.layout || 'DefaultLayout'
        }
    },
    methods: {
        ...mapActions(['fetchRefreshToken', 'fetchUser', 'clearTokens']),
    },
    async created() {
        this.isLoading = true

        await this
            .fetchUser()
            .then(() => {
                this.isLoading = false
            })
            .catch(() => {
                this
                    .fetchRefreshToken()
                    .then(() => {
                        this.fetchUser()
                            .finally(() => {
                                this.isLoading = false
                            })
                    })
                    .catch(() => {
                        this.clearTokens()
                        this.isLoading = false
                        this.$router.push('/login')
                    })
            })
    },
}
</script>

<style>

</style>
