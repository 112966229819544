import axios from "axios";
import store from "../store/main"

const authUrl = `${process.env.VUE_APP_API_URL}/api/users/auth`

const apiClient = axios.create({
    baseURL: `${process.env.VUE_APP_API_URL}/api/`,
    headers: {'Content-Type': 'application/json'},
})

apiClient.interceptors.request.use((config) => {
    if (config.url !== authUrl)

        config.headers.common['Authorization'] = "bearer " + store.getters.getAccessToken

    return config
})

export default apiClient