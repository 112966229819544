import {createStore} from "vuex"
import authenticate from "@/plugins/vuex/authenticate";
import emailAvailability from "@/plugins/vuex/emailAvailability";
import company from "@/plugins/vuex/company";
import manager from "@/plugins/vuex/manager";
import payment from "@/plugins/vuex/payment";

export default createStore({
    modules: {
        authenticate,
        emailAvailability,
        company,
        manager,
        payment
    }
})