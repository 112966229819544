import apiClient from "@/plugins/axios";

export default {
    actions: {
        fetchManagers(context) {
            return new Promise((resolve, reject) => {
                apiClient.get(`/partners`)
                    .then((response) => {

                        let managers = response.data['hydra:member']

                        context.commit('updateManagers', managers)
                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })
            })
        },
    },
    mutations: {
        updateManagers(state, managers) {
            state.managers = managers
        },
        updateLink(state, link) {
            state.link = link
        }
    },
    state: {
        managers: [],
        link: ''
    },
    getters: {
        getManagers(state) {
            return state.managers
        },
        getLink(state) {
            return state.link
        }
    },
}
