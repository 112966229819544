import localToken from "@/plugins/locals/localToken";
import apiClient from "../../plugins/axios";

export default {
    actions: {
        pushUser(context, data) {
            return new Promise((resolve, reject) => {
                apiClient.post(`/partners/make`, data)
                    .then((response) => {
                        context.commit('updateUser', response.data.user)
                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })
            })
        },
        putUser(context, data) {
            return new Promise((resolve, reject) => {
                apiClient.post(`/partners/update`, data)
                    .then((response) => {
                        context.commit('updateUser', response.data.user)
                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })
            })
        },
        fetchUser(context) {
            return new Promise((resolve, reject) => {
                apiClient.get(`users/about_me`)
                    .then((response) => {

                        let user = {
                            id: response.data.id,
                            givenName: response.data.person.givenName,
                            familyName: response.data.person.familyName,
                            telephone: response.data.person.telephone,
                            email: response.data.email,
                            jshshir: response.data.person.partner.jshshir,
                            moneyAmount: response.data.person.partner.moneyAmount,
                            partnerId: response.data.person.partner.id,
                            roles: response.data.roles
                        }
                        context.commit('updateUser', user)
                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })
            })
        },
        fetchToken(ctx, data) {
            return new Promise((resolve, reject) => {
                apiClient
                    .post(
                        '/users/auth',
                        data
                    )
                    .then(response => {
                        ctx.commit('updateTokens', response.data)
                        resolve()
                    })
                    .catch((reason) => {
                        console.log(reason)
                        reject(reason)
                    })
            })
        },
        fetchRefreshToken(ctx) {
            return new Promise((resolve, reject) => {
                apiClient
                    .post(
                        '/users/auth/refreshToken',
                        {refreshToken: ctx.getters.getRefreshToken}
                    )
                    .then(response => {
                        ctx.commit('updateTokens', response.data)
                        resolve()
                    })
                    .catch((reason) => {
                        console.log(reason)
                        reject(reason)
                    })
            })
        },
        clearTokens(ctx) {
            ctx.commit('clearTokens')
        }
    },
    mutations: {
        updateTokens(state, data) {
            state.accessToken = data.accessToken
            state.refreshToken = data.refreshToken

            localToken.setAccess(data.accessToken)
            localToken.setRefresh(data.refreshToken)
        },
        clearTokens(state) {
            state.accessToken = ''
            state.refreshToken = ''

            localToken.clear()
        },
        updateUser(state, user) {
            state.userAboutMe = user
        },
    },
    state: {
        accessToken: localToken.getAccess(),
        refreshToken: localToken.getRefresh(),
        userAboutMe: {
            id: null,
            givenName: null,
            familyName: null,
            telephone: null,
            email: null,
            jshshir: null,
            moneyAmount: null,
            partnerId: null,
            roles: [],
        },
    },
    getters: {
        isAuthenticated(state, getters) {
            return getters.getAccessToken.length > 0
        },
        getAccessToken(state) {
            return state.accessToken
        },
        getRefreshToken(state) {
            return state.refreshToken
        },
        getUserRoles(state) {
            return state.userAboutMe.roles
        },
        getUserInfo(state) {
            return state.userAboutMe
        },
        isAdmin(state, getters) {
            return getters.getUserRoles.includes('ROLE_ADMIN');
        },
    },
}
