const ACCESS_NAME = 'access-token'
const REFRESH_NAME = 'refresh-token'

export default {
    getAccess() {
        return localStorage.getItem(ACCESS_NAME) || ''
    },
    setAccess(token) {
        localStorage.setItem(ACCESS_NAME, token)
    },
    getRefresh() {
        return localStorage.getItem(REFRESH_NAME) || ''
    },
    setRefresh(token) {
        localStorage.setItem(REFRESH_NAME, token)
    },
    clear() {
        this.setAccess('')
        this.setRefresh('')
    }
}
