import { createApp } from 'vue'
import App from './App.vue'
import BootstrapVue3 from "bootstrap-vue-3";
import { BootstrapIconsPlugin,  } from 'bootstrap-icons-vue';
import router from "@/plugins/router";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-3/dist/bootstrap-vue-3.css";
import store from "./store/main"

createApp(App)
    .use(BootstrapVue3)
    .use(BootstrapIconsPlugin)
    .use(router)
    .use(store)

    .mount('#app')
