<template>
    <div class="d-flex justify-content-center mt-2 p-0">
        <div class="col p-0">
            <div :class="route.name === 'main' ? 'active mt-3 ps-4 py-1' : 'mt-3 ps-4 py-1'">
                <router-link to="/" class="no-underline">
                    <BIconColumnsGap />
                    <span class="sidebar-title ms-2">
                    Kompaniyalar
                </span>
                </router-link>
            </div>

            <div :class="route.name === 'learn' ? 'active mt-3 ps-4 py-1' : 'mt-3 ps-4 py-1'">
                <router-link to="/learn" class="no-underline">
                    <BIconFileText />
                    <span class="sidebar-title ms-2">
                    Dasturni o'rganish
                    </span>
                </router-link>
                <div
                    v-if="route.name === 'learn'"
                    class="ms-4 sublink"
                >
                    <div
                        :class="index === this.getLink.index ? 'mt-1 border-active' : 'border mt-1'"
                        v-for="(item, index) in this.links"
                        :key="index"
                        @click="sendLink(item)">
                            {{ item.name.slice(0, 25) }}...
                    </div>
                </div>
            </div>

            <div :class="route.name === 'help' ? 'active mt-3 ps-4 py-1' : 'mt-3 ps-4 py-1'">
                <router-link to="/help" class="no-underline">
                    <BIconInfoCircle />
                    <span class="sidebar-title ms-2">
                    Yordam
                </span>
                </router-link>
            </div>

            <div :class="route.name === 'personal-info' ? 'active mt-3 ps-4 py-1' : 'mt-3 ps-4 py-1'">
                <router-link to="/personal-info" class="no-underline">
                    <BIconPerson />
                    <span class="sidebar-title ms-2">
                    Shaxsiy ma'lumotlar
                </span>
                </router-link>
            </div>

            <div v-if="is_Admin" :class="route.name === 'manager' ? 'active mt-3 ps-4 py-1' : 'mt-3 ps-4 py-1'">
                <router-link to="/manager" class="no-underline">
                    <BIconPerson />
                    <span class="sidebar-title ms-2">
                    Menejerlar ro'yxati
                </span>
                </router-link>
            </div>

            <div v-if="is_Admin" :class="route.name === 'payment' ? 'active mt-3 ps-4 py-1' : 'mt-3 ps-4 py-1'">
                <router-link to="/payment" class="no-underline">
                    <BIconPerson />
                    <span class="sidebar-title ms-2">
                    To'lov uchun so'rovlar
                </span>
                </router-link>
            </div>

            <div class="mt-3 ps-4 py-1">
                <router-link @click="logout" to="" class="no-underline">
                    <BIconBoxArrowInLeft />
                    <span class="sidebar-title ms-2">
                    Chiqish
                </span>
                </router-link>
            </div>

        </div>

    </div>
</template>

<script>
import {useRoute} from "vue-router/dist/vue-router";
import {mapMutations, mapGetters} from "vuex";

export default {
    name: "SideBar",
    data()  {
        return {
            links: [
                {name: "Ish boshlash uchun qisqa qo'llanma",link: 'https://youtube.com/embed/Dc_KRh5FauA', index: 0},
                {name: "Boshqar.com da mahsulotni import qilish",link: 'https://youtube.com/embed/JJEnJyk-ylo', index: 1},
                {name: "BoshqarCom da mahsulot xarid qilish",link: 'https://youtube.com/embed/5633py1ze_Q', index: 2},
                {name: "Boshqar mobil ilovasida ombor qo'shish va filtr",link: 'https://youtube.com/embed/Ha1aoy3EHa4', index: 3},
                {name: "Boshqar mobil ilovasida mijozga mahsulot sotish",link: 'https://youtube.com/embed/tzEHsDVvTwg', index: 4},
                {name: "Boshqar mobil ilovasida ombordagi mahsulotlarni ko'rish",link: 'https://youtube.com/embed/2gWh0ZMSbys', index: 5},
                {name: "Boshqar mobil ilovasida mahsulotni omborga joylash",link: 'https://youtube.com/embed/110IBwRcGE4', index: 6},
                {name: "Boshqar mobil ilovasida ombordagi mahsulot qancha qolganligini ko'rish",link: 'https://youtube.com/embed/VqgOkjp0BWM', index: 7},
                {name: "Boshqar mobil ilovasida ishchi qo'shish",link: 'https://youtube.com/embed/9cBL2sLokcE', index: 8},
                {name: "BoshqarCom da ro'yxatdan o'tish",link: 'https://youtube.com/embed/8k8vdnkTyM0', index: 9},
                {name: "Mahsulot qo'shish, sotib olish va ombor",link: 'https://youtube.com/embed/aDQji_DaqAc', index: 10},
                {name: "Boshqar dasturida mijoz, ishchi va lavozim qo'shish",link: 'https://youtube.com/embed/hhBI0Ejhwn8', index: 11},
                {name: "BOSHQAR dasturida statistika bo'limini ko'rib chiqamiz",link: 'https://youtube.com/embed/WiIDcFjuv9Q', index: 12},
                {name: "Boshqar dasturining bo'lib to'lash funksiyasi",link: 'https://youtube.com/embed/qJqpSYB6828', index: 13},
                {name: "Mahsulotni import qilishda valyutada import qilish imkoni qo'shildi",link: 'https://youtube.com/embed/Y1w3KbFaz-o', index: 14}
            ]
        }
    },
    computed: {
        ...mapGetters(['isAdmin', 'getLink']),
        route() {
            return  useRoute()
        },
        is_Admin() {
            return this.isAdmin
        }
    },
    methods: {
        ...mapMutations(['updateLink']),
        logout() {
            this.$store.dispatch('clearTokens')
            this.$router.push('/login')
        },
        sendLink(link) {
            this.updateLink(link)
        }
    }
}
</script>

<style scoped>
.sidebar-title {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #817E7E;
}

.no-underline {
    text-decoration: none;
}

.active {
    background-color: #F6EFFF;
    padding: 16px 0;
}

.sublink {
    width: 450px;
    overflow: hidden;
    cursor: pointer;
    font-size: 14px;
}

.underline {
    text-decoration: underline;
    font-size: 15px;
    transform-style: preserve-3d;
    transition-duration: 0.2s;
    overflow: hidden;
}

.border {
    border-bottom: 1px solid #949292;
    height: 26px;
    width: 150px;
    overflow: hidden;
    border-radius: 5px;
}

.border-active {
    border: 1px solid #A162F7;
    height: 28px;
    width: 152px;
    overflow: hidden;
    border-radius: 5px;
    transition-duration: 0.2s;
    box-shadow: 4px 4px 4px 0 rgba(0, 0, 0, .15);

}
</style>