<template>
        <div class="row balans mt-3">
            <div class="d-flex justify-content-center">
                <div class="col-11 card">
                    <loading :active="isLoading"></loading>

                    <table class="mx-3 mt-2" cellpadding="4">
                        <thead>
                        <tr style="border-bottom: 1px solid #e7e6e6">
                            <td>Kompaniya nomi</td>
                            <td>Ro'yxatdan o'tgan sanasi</td>
                            <td>Oxirgi to'lov</td>
                            <td>Sanasi</td>
                        </tr>
                        </thead>
                        <tbody v-for="(company, index) of getUserCompanies" :key="index">
                        <tr style="border-bottom: 0.5px solid #e7e6e6">
                            <td>
                                {{ company.name }}
                            </td>
                            <td> {{ convertDateAnnouncement(company.createdAt) }} </td>
                            <td v-if="company.planPurchases.length > 0"> {{ numberWithCommas(Math.round(company.planPurchases[0].payed)) }} </td>
                            <td v-if="company.planPurchases.length > 0"> {{ convertDateAnnouncement(company.planPurchases[0].createdAt) }} </td>
                        </tr>
                        </tbody>
                    </table>


                </div>
            </div>
        </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
    name: "MainPage",
    components: {Loading},
    data() {
        return {
            isLoading: true
        }
    },
    computed: {
        ...mapGetters(['getUserCompanies', 'getUserRoles', 'getUserInfo'])
    },
    methods: {
        ...mapActions(['fetchUserCompanies']),
        convertDateAnnouncement(inputFormat) {
            function pad(s) {
                return (s < 10) ? '0' + s : s;
            }

            let d = new Date(inputFormat)
            return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('.')
        },
        numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },
    },
    mounted() {
        this.isLoading = true
        this.fetchUserCompanies()
            .then(() => {
                this.isLoading = false
            })
            .catch(() => {
                console.log()
            })
    }
}
</script>

<style scoped>

.balans {
    height: 90vh;
    background-color: #F5F5F5;;
}

.card {
    border: none;
    margin-top: 3%;
    height: 400px;
}

</style>